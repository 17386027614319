<template>
  <base-dialog
    ref="new_user"
    type="fab"
    title="Iniciar sesión"
    icon="mdi-plus"
    small
    :width="type == 'studio' ? 1000 : 500"
    @open="open"
  >
    <form
      action
      data-vv-scope="extra-user-form"
      v-if="type == 'login' || !$store.getters['auth/isStudio']"
    >
      <v-row justify="center">
        <v-col cols="12" class="py-0">
          <label v-text="$t('email')"></label>
          <v-text-field
            outlined
            dense
            v-model="new_user.email"
            data-vv-validate-on="blur"
            v-validate="'required|email|min:2'"
            :error-messages="errors.collect('extra-user-form.email')"
            hide-details
            data-vv-name="email"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <label v-text="$t('password')"></label>
          <v-text-field
            outlined
            dense
            v-model="new_user.password"
            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            data-vv-validate-on="blur"
            hide-details
            v-validate="'required|min:2'"
            :error-messages="errors.collect('extra-user-form.password')"
            data-vv-name="password"
          />
        </v-col>
      </v-row>
    </form>
    <v-row v-else-if="type == 'studio'">
      <StudioForm :studio="studio" :buttons="false" ref="studioForm" />
    </v-row>
    <v-row v-else>
      <v-col>
        <base-border-card
          title="Nuevo usuario"
          @card_clicked="type = 'login'"
        ></base-border-card>
      </v-col>
      <v-col>
        <base-border-card
          title="Crear estudio"
          @card_clicked="type = 'studio'"
        ></base-border-card>
      </v-col>
    </v-row>
    <template slot="actions" v-if="type">
      <v-spacer />
      <v-btn
        @click="$refs.new_user.close()"
        outlined
        style="height: 25px; width: 100px"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        @click="newUser"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
        >{{ $tc("save", { name: "" }) }}</v-btn
      >
    </template>
  </base-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import constants from "@/constants";
export default {
  name: "NewUser",
  data() {
    return {
      new_user: {},
      type: null,
      studio: {
        user: { role_id: constants.roles.STUDIO },
        main_studio: false,
        timetable: this.$store.getters["timetables/basic"],
      },
    };
  },
  components: {
    StudioForm: () => import("@/components/studio/Form"),
  },
  methods: {
    open() {
      this.type = null;
    },
    close() {
      this.$refs.new_user.close();
      this.$emit("close");
      this.new_user = {};
      this.studio = {
        user: { role_id: constants.roles.STUDIO },
        main_studio: false,
        timetable: this.$store.getters["timetables/basic"],
      };
      this.$router.push({ name: "NewUser" });
    },
    newUser() {
      //console.log("validando");
      switch (this.type) {
        case "login":
          this.login();
          break;
        case "studio":
          this.createStudio();
          break;
      }
    },
    login() {
      this.errors.clear();
      this.$validator.validateAll("extra-user-form").then((result) => {
        if (result)
          this.$store
            .dispatch("auth/authenticate", {
              email: this.new_user.email,
              password: this.new_user.password,
            })
            .then(() => {
              this.close();
            });
        //else console.log("ERROR DE VALIDACION");
      });
    },
    createStudio() {
      let validation = this.$refs.studioForm.validateForm();
      if (validation) {
        //TODO ACTION
        this.$store
          .dispatch("studios/associateNewStudioToUser", this.studio)
          .then(() => {
            this.close();
          });
      }
    },
  },
};
</script>